export enum paymentType {
  CREDIT_CARD = 'credit_card',
}

export enum result {
  AUTHORIZED = 'authorized',
  DECLINED = 'declined'
}

export enum status {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export interface Segpay {
  id: Number
  start_date: String
  end_date: String
  payment_type: paymentType
  result: String
  transaction_amount: Number
  transaction_fee: Number
  settlement_fee: Number
  reserve_held: Number
  total_fee: Number
  total_net: Number
  total_payout_amount: Number
  status: String
  payout_date: String
}

export type PartialSegpay = Partial<Segpay>;
